// eslint-disable-next-line
export const VALID_DEEPLINK_PATTERN = /[a-zA-Z0-9]+:\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
// eslint-disable-next-line
export const VALID_LINK_PATTERN = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
// eslint-disable-next-line
export const VALID_SEMANTIC_VERSION = /^(\d+)\.(\d+)\.(\d+)-?(\d*)?$/;
// eslint-disable-next-line
export const VALID_SEMANTIC_VERSION_OS = /^(\d+)\.(\d+)-?(\d*)?$/;
// eslint-disable-next-line
export const VALID_SEMANTIC_VERSION_ONE_UI = /^(\d+)\.(\d+)-?(\d*)?$|^(\d+)\.(\d+)\.(\d+)-?(\d*)?$/;

export const VALID_SEMANTIC_VERSION_ST_KIT = /^(\d){1}\.(\d){1}\.(\d){2}\.(\d){1}$/;

export const EMPTY_SPACE_REGEX = /^$|\s+/;

export const RECURRING_METHOD = {
  NOT_DISMISS: 'NOT_DISMISS',
  ALL: 'ALL',
  NO_ACTION: 'NO_ACTION',
};
export const DEVICE_TYPE = [
  { value: 'light', label: 'Light' },
  { value: 'tv', label: 'TV' },
  { value: 'ac', label: 'Air Conditioner' },
  { value: 'dish_washer', label: 'Dish Washer' },
  { value: 'washing_machine', label: 'Washing Machine' },
];
export const DEVICE_TYPE_TRIGGER = [
  { value: 'LIGHT', label: 'Light' },
  { value: 'TV', label: 'TV' },
  { value: 'AC', label: 'Air Conditioner' },
  { value: 'DISH_WASHER', label: 'Dish Washer' },
  { value: 'WASHING_MACHINE', label: 'Washing Machine' },
];
export const TRIGGER_TYPE = [
  { value: 'DEVICE_CONNECTED', label: 'Device Connected' },
  { value: 'APP_UPDATE', label: 'App Update' },
  { value: 'NONE', label: 'None' },
];
export const TEMPLATE_TYPE = {
  ICON_TEXT: 'ICON_TEXT',
  ICON_ANIMATION_TEXT: 'ICON_ANIMATION_TEXT',
  THUMBNAIL_TEXT: 'THUMBNAIL_TEXT',
  TEXT_ONLY: 'TEXT_ONLY',
  BACKGROUND_TYPE: 'BACKGROUND_TYPE',
};
export const TEMPLATE = [
  { value: TEMPLATE_TYPE.TEXT_ONLY, label: 'Text only' },
  { value: TEMPLATE_TYPE.ICON_TEXT, label: 'Icon Image + Text' },
  { value: TEMPLATE_TYPE.ICON_ANIMATION_TEXT, label: 'Icon Animation + Text' },
  { value: TEMPLATE_TYPE.THUMBNAIL_TEXT, label: 'Image + Text' },
  { value: TEMPLATE_TYPE.BACKGROUND_TYPE, label: 'Image BG + Text' },
];
export const OS_TYPE = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Android',
    value: 'android',
  },
  { label: 'iOS', value: 'ios' },
];
export const SPECIFIC_DEVICE = [
  {
    value: 'ABL',
    label: 'ABL',
  },
  {
    value: 'PHILIPS',
    label: 'Philips',
  },
  {
    value: 'LG',
    label: 'LG',
  },
];
export const OPTIONS_RECURRING_METHOD = [
  {
    value: 'NOT_DISMISS',
    label: 'Not Dismiss',
  },
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'NO_ACTION',
    label: 'No Action',
  },
];
export const INSTALLED_SERVICE = [
  {
    value: 'ST_AIR',
    label: 'ST Air',
  },
  {
    value: 'ST_ENERGY',
    label: 'ST Energy',
  },
  {
    value: 'SHM',
    label: 'SHM',
  },
  {
    value: 'STC',
    label: 'ST Care (TBD)',
  },
];
export const CARRIER = [
  {
    value: 'VODAFONE',
    label: 'Vodafone',
  },
  {
    value: 'SINGTEL',
    label: 'Singtel',
  },
  {
    value: 'AMX',
    label: 'AMX',
  },
];
export const CAMPAIGN_TYPE = [
  {
    value: 'FEATURE',
    label: 'Feature',
    disabled: false,
  },
  {
    value: 'PROMOTION',
    label: 'Promotion',
    disabled: false,
  },
  {
    value: 'LIST_ONLY',
    label: 'List-only',
    disabled: false,
  },
  {
    value: 'CLIENT_TRIGGERED',
    label: 'Client Triggered',
    disabled: false,
  },
  // {
  //   value: 'PERSONAL_TYPE',
  //   label: 'Personal Type',
  // },
];
export const SUB_CATEGORY = [
  {
    value: 'DEVICE_GROUP',
    label: 'Device Group',
  },
  {
    value: 'SMART_APPS',
    label: 'SmartApps',
  },
  {
    value: 'RECIPE',
    label: 'Recipe',
  },
  {
    value: 'SCENE',
    label: 'Scene',
  },
  {
    value: 'AUTOMATION',
    label: 'Automation',
  },
  {
    value: 'OTHER_FEATURES',
    label: 'Other Features',
  },
];

export const ACTION_RECOMMENDATION_TYPE = {
  RECIPE: 'RECIPE',
  WEB_LINK: 'WEB_LINK',
  SERVICE_PLUGIN: 'SERVICE_PLUGIN',
  DEEP_LINK: 'DEEP_LINK',
  ENDPOINT_APP: 'ENDPOINT_APP',
  GROOVY_SMART_APP: 'GROOVY_SMART_APP',
  SCENE: 'SCENE',
  DEVICE_GROUP: 'DEVICE_GROUP',
  ROUTINE: 'ROUTINE',
  NO_ACTION: 'NO_ACTION',
  INSTANT_VIEW: 'INSTANT_VIEW',
};

export const ACTION_INSTANT_VIEW_TYPE = {
  TEXT_ONLY: 'TEXT_ONLY',
  TEXT_IMAGE: 'TEXT_IMAGE',
  TEXT_ANIMATION_IMAGE: 'TEXT_ANIMATION_IMAGE',
};

export const CAMPAIGN_TYPE_KEY_BY_VALUE = {
  FEATURE: 'FEATURE',
  PROMOTION: 'PROMOTION',
  LIST_ONLY: 'LIST_ONLY',
  CLIENT_TRIGGERED: 'CLIENT_TRIGGERED',
};

export const INSTANT_VIEW_TYPE = {
  textOnly: 'textOnly',
  textImage: 'textImage',
  textAnimation: 'textAnimationImage',
  deepLink: 'deepLink',
};

export const ACTION_OPT = [
  {
    value: ACTION_RECOMMENDATION_TYPE.DEEP_LINK,
    label: 'Deep Link',
  },
  {
    value: ACTION_RECOMMENDATION_TYPE.WEB_LINK,
    label: 'Web Link',
  },
  {
    value: ACTION_RECOMMENDATION_TYPE.SERVICE_PLUGIN,
    label: 'Service Plugin',
  },
  {
    value: ACTION_RECOMMENDATION_TYPE.ENDPOINT_APP,
    label: 'Endpoint App',
  },
  {
    value: ACTION_RECOMMENDATION_TYPE.GROOVY_SMART_APP,
    label: 'Groovy Smart App',
  },
  {
    value: ACTION_RECOMMENDATION_TYPE.SCENE,
    label: 'Preset Scene',
  },
  {
    value: ACTION_RECOMMENDATION_TYPE.DEVICE_GROUP,
    label: 'Device Group',
  },
  {
    value: ACTION_RECOMMENDATION_TYPE.ROUTINE,
    label: 'Add Routine',
  },
  {
    value: ACTION_RECOMMENDATION_TYPE.NO_ACTION,
    label: 'No Action',
  },
  // TBD
  // {
  //   value: ACTION_TYPE.RECIPE,
  //   label: 'Recipe',
  // },
];

export const LOCATION_TYPES = {
  COUNTRY: 'COUNTRY',
  REGION: 'REGION',
};

export const LOCATION_TYPE_OPTS = [
  { value: 'COUNTRY', label: 'Country' },
  { value: 'REGION', label: 'Region' },
];

export const ERROR_MSG = {
  connectedDevice: 'Connected Device field is required',
  mobileDevice: 'Mobile device field is required',
  deviceGroup: 'Number of Device Group field is required',
  title: 'Title field is required',
  text: 'Text field is required',
  description: 'Description field is required',
  priority: 'Weight field must be a number',
  installedService: 'Installed Service field is Required',
  carrier: 'Carrier field is Required',
  minAppVersion: 'ST App Version field is required',
  numberOfScene: 'Number of Scene field must be a number',
  languageMap: 'All of language fields must be filled',
  translationText: 'Translation text field cannot be empty',
  actionSubTitleText: 'SubTitle text field cannot be empty',
  actionBodyText: 'Body text field cannot be empty',
  actionButtonText: 'Button text field cannot be empty',
  translationButtonText: 'Translation button text field cannot be empty',
  translationSubTitleText: 'Translation Subtitle text field cannot be empty',
  subTitleColor: 'Subtitle color field cannot be empty',
  hexColor: 'Input must be a valid HEX color',
  defaultWebUrl: 'Default Web URL field cannot be empty',
  webUrl: 'web URL field cannot be empty',
  urlInvalid: 'Input must be a valid Link format',
  deepLinkInvalid: 'Input must be a valid deeplink format',
  countryUri: 'Input must be a valid Link format',
  countryCid: 'CID field cannot be empty',
  mustInput: 'One of UID and URI fields must be filled',
  uriField: 'URI fields cannot be empty',
  dataField: 'Data fields cannot be empty',
  dataJSON: ' Data field must be valid data JSON',
  translationDisplayName: 'Translation display name cannot be empty',
  actionData: 'Action data cannot be empty',
  invalidAppVersion: 'App version must be a valid format (e.g. "1.0.2")',
  invalidOSVersion: 'OS version must be a valid format (e.g. "1.01")',
  invalidOneUIVersion:
    'One UI version must be a valid format (e.g. "1.01" or "1.0.2")',
  invalidSTKitVersion:
    'SmartThings Kit version must be a valid format (e.g. "1.5.01.0" or "1.5.01.1")',
  countryArray: 'Country is required! cannot be empty',
  languageArray: 'Language is required! cannot be empty',
  routineTemplate: 'Routine template fields cannot be empty',
};
